<template>
  <div class="range_container">
    <input type="range"
           :min="rangeMin"
           :max="rangeMax"
           :step="rangeStep"
           v-bind:value="value"
           v-on="inputListeners"
    >
    <div class="fmRange" ref="fmRange">
      <div class="fmRangeBar"></div>
      <div class="fmRangeProgress" v-bind:style="{width:rangeProgress + 'px'}"></div>
      <div class="fmRangeTumb" v-bind:style="{left:rangeProgress + 'px'}" ref="fmRangeTumb"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "range",
    data(){
      return {
        kostilValue:0,
      }
    },
    props: {
      value: [Number,String],
      rangeMin:{
        type:Number,
        default:5000,
      },
      rangeMax:{
        type:Number,
        default: 10000,
      },
      rangeStep:{
        type:Number,
        default: 1000,
      },

    },
    mounted() {

    },
    methods: {
      trigerDefaultValue(value){
        this.kostilValue = value;
      }
    },
    computed: {
      inputListeners: function () {
        var vm = this;
        // `Object.assign` объединяет объекты вместе, чтобы получить новый объект
        return Object.assign({},
          // Мы добавляем все слушатели из родителя
          this.$listeners,
          // Затем мы можем добавить собственные слушатели или
          // перезаписать поведение некоторых существующих.
          {
            // Это обеспечит, что будет работать v-model на компоненте
            input: function (event) {
              vm.$emit('input', event.target.value);
              vm.kostilValue = event.target.value;
            }
          }
        )
      },
      rangeProgress() {
        let selectRange = this.kostilValue - this.rangeMin;
        let selectRangeMax = this.rangeMax - this.rangeMin;
        if (selectRangeMax === 0) return 0;

        if (this.$refs.fmRange !== undefined) {
          let tumbWidth = this.$refs.fmRangeTumb.clientWidth / 2;
          let delta = (selectRange < (selectRangeMax / 2)) ? tumbWidth : tumbWidth * (-1);
          let clW = this.$refs.fmRange.clientWidth;
          return Math.round(((selectRange * clW) / selectRangeMax) + delta);
        } else {
          return 0;
        }
      },

    }
  }
</script>

<style scoped>

  .range_container {position: relative;width: 100%; /*input range width*/height: 25vw; /*input range height*/
    /*overflow: hidden;*/

  }

  input[type='range'] {width: 100%;height: 100%;-webkit-appearance: none;
    opacity: 0;cursor: ew-resize;
  }
  input[type=range]::-webkit-slider-thumb {-webkit-appearance: none;height: 25vw;width: 12vw;background-color: red;}

  /* All the same stuff for Firefox */
  input[type=range]::-moz-range-thumb {height: 25vw;width: 12vw;cursor: pointer;}

  /* All the same stuff for IE */
  input[type=range]::-ms-thumb {height: 25vw;width: 12vw;cursor: pointer;}

  .fmRange {pointer-events: none;
    /*width: calc(100% - 8vw);*/
    /*left: 4vw;*/
    width: 100%;
    position: absolute;top: 0;height: 100%;
    /*opacity: 0.3;*/
  }
  .fmRangeBar {position: absolute;top: calc(50% - 1vw);left: 0;width: 100%;height: 2vw;background: white;border-radius: 2vw;}
  .fmRangeProgress {position: absolute;top: calc(50% - 1vw);left: 0;height: 2vw;background: linear-gradient(to right, #4AAF3D, #97DD5F);border-radius: 2vw;}
  .fmRangeTumb {position: absolute;top: 6vw;margin-left: -4vw;height: 12vw;width: 12vw;border-radius: 100%;background: #4AAF3D;cursor: pointer}
  .fmRangeTumb:after {content: '';position: absolute; top: calc(50% - 2vw);left: calc(50% - 2vw);width: 4vw; height:
    4vw;background: white;border-radius: 100%;}

  @media screen and (min-width: 760px) {
    .range_container {height: 2.7vw;}
    input[type=range]::-webkit-slider-thumb {-webkit-appearance: none;height: 2.7vw;width: 5vw;background-color: red;}
    /* All the same stuff for Firefox */
    input[type=range]::-moz-range-thumb {width: 5vw;height: 2.7vw;cursor: pointer;}
    /* All the same stuff for IE */
    input[type=range]::-ms-thumb {width: 5vw;height: 2.7vw;cursor: pointer;}

    /*.fmRange {width: calc(100% - 8vw);left: 4vw;}*/
    .fmRangeBar {position: absolute;top: calc(50% - 0.3vw);left: 0;width: 100%;height: 0.6vw;background: white;border-radius: 2vw;}
    .fmRangeProgress {top: calc(50% - 0.3vw);left: 0;height: 0.6vw;}
    .fmRangeTumb {top: 0;margin-left: -1.2vw;height: 2.4vw;width: 2.4vw;}
    .fmRangeTumb:after {top: calc(50% - .6vw);left: calc(50% - .6vw);width: 1.2vw; height: 1.2vw;border-radius: 4vw;}
  }
  @media screen and (min-width: 1140px) {
    .range_container {height: 30px;}
    input[type=range]::-webkit-slider-thumb {-webkit-appearance: none;height: 30px;width: 30px;background-color: red;}
    /* All the same stuff for Firefox */
    input[type=range]::-moz-range-thumb {width: 30px;height: 30px;cursor: pointer;}
    /* All the same stuff for IE */
    input[type=range]::-ms-thumb {width: 30px;height: 30px;cursor: pointer;}

    /*.fmRange {width: calc(100% - 8vw);left: 4vw;}*/
    .fmRangeBar {position: absolute;top: calc(50% - 3px);left: 0;width: 100%;height: 6px;background: white;border-radius: 2vw;}
    .fmRangeProgress {top: calc(50% - 3px);left: 0;height: 6px;}
    .fmRangeTumb {top: 0;margin-left: -15px;height: 30px;width: 30px;}
    .fmRangeTumb:after {top: calc(50% - 8px);left: calc(50% - 8px);width: 16px; height: 16px;border-radius: 4vw;}
  }
</style>
